import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { JwtdecodeService } from './jwtdecode.service';
import { LocalStorageService } from './localStorage.service';
import { LoginService } from './login.service';
import { IUser } from '../models/IUser';
import { Router } from '@angular/router';
import { map, throwError } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private apiUrl: string;

  constructor(@Inject('API_URL') apiUrl: string,
    private http: HttpClient,
    private jwtdecodeService: JwtdecodeService,
    private localStorageService: LocalStorageService,
    private loginService: LoginService,
    private router: Router) {
    this.apiUrl = apiUrl;
  }

  public getJwtToken() {
    return this.localStorageService.get('token');
  }

  private getRefreshToken() {
    return 'refreshToken';
  }

  refreshToken() {
    const currentaccessToken = this.localStorageService.get('token');

    const ttr = sessionStorage.getItem('ttr');

    if (ttr) {
      let user64 = atob(ttr);
      let userDecode = user64.split(';');

      let user: IUser = {
        Email: userDecode[0],
        Password: userDecode[1]
      };

      let basicToken = btoa(unescape(encodeURIComponent(user.Email + ':' + user.Password)));
      console.log(basicToken);
      sessionStorage.setItem('ttr', basicToken);
      const headerDict = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Allow-Headers': 'Content-Type',
        'Authorization': 'Basic ' + basicToken
      }

      const requestOptions = {
        headers: new HttpHeaders(headerDict),
      };

      return this.http.get(`${this.apiUrl}/login/token`, requestOptions).pipe(map(result => { return result; }))
    }
    else {
      this.logoutUser();
      return throwError('user logged out');
    }
  }

  public logoutUser() {
    this.localStorageService.clear();
    this.router.navigateByUrl('/auht/login');
  }
}